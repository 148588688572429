@font-face {
  font-family: "LCD Solid";
  src: url("./fonts/LcdSolid.ttf") format("truetype");
}

@font-face {
  font-family: "Noah Regular";
  src: url("./fonts/Noah-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noah Bold";
  src: url("./fonts/Noah-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-SemiBoldItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-HeavyItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "NoirPro";
  src: url("./fonts/NoirPro-Regular.otf") format("opentype");
  font-weight: normal;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Noto Serif Georgian";
  src: url("./fonts/NotoSerifGeorgianVariable.ttf") format("truetype");
  font-weight: normal;
  font-weight: 400;
  font-style: normal;
  /* wdth:
  wght: */
}


* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #202025;
}


@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-field-login::placeholder {
  font-family: "Noah Regular";
}
