.translationFullView {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100svh;
  width: 100vw;
  z-index: 1001;
  color: black;
  background-color: white;
  font-family: 'NoirPro';
  font-weight: 400;

  /* Add container query */
  container-type: inline-size;
  container-name: translationContainer;

  .translation,
  .source {
    padding: 0vh 5vw;
  }

  .translation {
    text-align: left;
    font-weight: 600;
    cursor: pointer;

    .textNormal {
      color: var(--color-text-green);
      font-size: clamp(3vw, 10vh, 15vh);
      /* Use container query to adjust font size based on container width */
      @container translationContainer (min-width: 600px) {
        div {
          transition: font-size 0.2s ease-in-out;
          font-size: clamp(3vw, 10vh, 15vh);
          &:hover {
            font-size: clamp(3vw, 12vh, 15vh);
          }
          &:not(:hover) {
            font-size: clamp(3vw, 9vh, 15vh);
          }
        }
      }

      @container translationContainer (max-width: 600px) {
        font-size: clamp(2vw, 8vh, 10vh);
      }
    }

    .highlight {
      color: var(--color-text);
      font-size: clamp(5vw, 8vh, 14vh);
    }
  }

  .source {
    text-align: right;
    color: #b6b6b6;
    font-weight: 400;
    align-self: flex-end;
    cursor: pointer;

    .textNormal {
      /* Use container query to adjust font size based on container width */

      font-size: clamp(2vw, 4vh, 10vh);
      @container translationContainer (min-width: 600px) {
        div {
          font-size: clamp(2vw, 4vh, 10vh);
          transition: font-size 0.2s ease-in-out;
          &:hover {
            font-size: clamp(2vw, 5vh, 10vh);
          }
        }
      }

      @container translationContainer (max-width: 600px) {
        div {
          font-size: clamp(2vw, 3.5vh, 5.5vh);
          transition: font-size 0.2s ease-in-out;
          &:hover {
            font-size: clamp(2vw, 5vh, 10vh);
          }
        }
      }
    }

    .highlight {
      color: var(--color-text);
      font-size: clamp(4vw, 7vh, 10vh);
    }
  }
}
