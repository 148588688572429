.inspirationFullView {
  position: fixed; /* Fixed to cover entire viewport */
  display: flex;
  flex-direction: column; /* Allow for multiple inspirations vertically */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  height: 100vh;
  width: 100vw;
  z-index: 1001; /* Ensure high enough to be on top */
  color: white;
  font-size: 3vh;
  background-color: black;
  font-family: 'Noah Bold';
  padding: 10vw 20vh;
  overflow: hidden; /* Hide overflow for smooth transitions */
  @media screen and (max-width: 768px) {
    font-size: 2vw;
    padding: 5vw 5vh;
  }
}

.inspiration {
  text-align: center; /* Center text horizontally */
  position: absolute; /* Allow each inspiration to animate freely */
  opacity: 0; /* Start with invisible text (we'll animate opacity) */
  color: white;
}
