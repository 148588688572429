.loginDiv {
  display: flex;
  .logoContainer {
    padding: 16px;
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  padding: 8px;
}
