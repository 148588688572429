.translationFullView {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100svh;
  width: 100vw;
  z-index: 1001;
  color: black;
  background-color: white;
  font-family: 'NoirPro';
  font-weight: 400;
  overflow-y: scroll;

  /* Add container query */
  container-type: inline-size;
  container-name: translationContainer;

  .translation,
  .source {
    padding: 0vh 5vw;
  }

  .spoken {
    text-align: left;
    font-weight: 600;
    cursor: pointer;

    .textNormal {
      color: var(--color-text-green);
      font-size: clamp(3vw, 10vh, 10vh);

      /* Use container query to adjust font size based on container width */
      @container translationContainer (min-width: 600px) {
        font-size: clamp(3vw, 10vh, 15vh);
      }

      @container translationContainer (max-width: 600px) {
        font-size: clamp(2vw, 8vh, 10vh);
      }
    }

    .highlight {
      color: var(--color-text);
      font-size: clamp(5vw, 8vh, 14vh);
    }
  }

  .translation {
    text-align: right;
    color: #b6b6b6;
    font-weight: 400;
    align-self: flex-end;
    cursor: pointer;
    padding-bottom: 32px;

    .textNormal {
      font-size: clamp(2.5vw, 4vh, 10vh);

      /* Use container query to adjust font size based on container width */
      @container translationContainer (min-width: 600px) {
        font-size: clamp(2vw, 3vh, 10vh);
      }

      @container translationContainer (max-width: 600px) {
        font-size: clamp(2vw, 3.5vh, 5.5vh);
      }
    }

    .highlight {
      color: var(--color-text);
      font-size: clamp(4vw, 7vh, 10vh);
    }
  }
}
